import React from "react";
import ApplicationList from "../ApplicationList";
import BusImageContent from "../BusImageContent";
import AmazingFeatures from "../AmazingFeatures";

const Features = () => {
  return (
    <>
      <ApplicationList />
      <BusImageContent />
      <AmazingFeatures />
    </>
  );
};

export default Features;
