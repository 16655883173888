import React from "react";
import { Link, NavLink } from "react-router-dom";
import HeaderTopbar3 from "../HeaderTopbar3/HeaderTopbar3";
import MobileMenu from "../MobileMenu/MobileMenu";

const Header4 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header id="header" className={`wpo-header-style-3 ${props.topbarNone}`}>
      <HeaderTopbar3 />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="navbar-header">
                  <Link onClick={ClickHandler} className="navbar-brand" to="/">
                    <img src={props.Logo} alt="" height={45} />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li>
                      <NavLink
                        onClick={ClickHandler}
                        to="/"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={ClickHandler} to="/about">
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={ClickHandler} to="/features">
                        Features
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={ClickHandler} to="/contact">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Header4;
