import React from "react";
import mainImage from "../../images/about-company.jpg";
import core from "../../images/core-values.png";

const About4 = (props) => {
  return (
    <section className="wpo-about-section-s4 section-padding pb-120">
      <div className="container">
        <div className="wpo-about-inner" style={{ background: "transparent" }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <h4>ABOUT OUR COMPANY</h4>
                <h2>Best IT Technology Services you can Trust.</h2>
                <h5>We build a solution. We deliver.</h5>
                <p>
                  Netpair Infotech is a leading Global Tech. Company
                  headquartered in Gujarat, India. We provide a broad range of
                  services & solutions with digital consultation and development
                  in a strategic, innovative, and creative style. We specialize
                  in transforming our customers’ business continuously by
                  providing the right insight, deploying the most sought after
                  technology solutions.
                </p>
                <div className="text-sub-wrap">
                  <div className="text-sub-item">
                    <div className="icon">
                      <img src={core} alt="Core values" srcset="" />
                    </div>
                    <h5>Core Values That We Possess</h5>
                  </div>

                  <div className="core-values">
                    <ul>
                      <li>
                        <span>Commitment</span>
                      </li>
                      <li>
                        <span>Determination</span>
                      </li>
                      <li>
                        <span>Respect</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>Courage</span>
                      </li>
                      <li>
                        <span>Morality</span>
                      </li>
                      <li>
                        <span>Truthfulness</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-wrap">
                <div className="wpo-about-img">
                  <div className="inner-img">
                    <img src={mainImage} alt="About our company" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-ab"></div>
    </section>
  );
};

export default About4;
