import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from "../../images/slider/route-gif.gif";
import hero2 from "../../images/slider/leave-gif.gif";
import hero3 from "../../images/slider/sos-gif.gif";

const settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  fade: true,
};

const Hero2 = () => {
  return (
    <section className="wpo-hero-slider">
      <div className="hero-container">
        <div className="hero-wrapper">
          <Slider {...settings}>
            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${hero1})` }}
              >
                <div className="container-fluid">
                  <div className="slide-content">
                    <div className="slide-title-sub">
                      <h5>All solutions at one palce.</h5>
                    </div>
                    <div className="slide-title">
                      <h2>The Ultimate Transportation Technology Suite.</h2>
                    </div>
                    <div className="slide-text">
                      <p>Accountability Brings Peace of Mind</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${hero2})` }}
              >
                <div className="container-fluid">
                  <div className="slide-content">
                    <div className="slide-title-sub">
                      <h5>Think big, think innovative.</h5>
                    </div>
                    <div className="slide-title">
                      <h2>Improve Every Aspect of Journey.</h2>
                    </div>
                    <div className="slide-text">
                      <p>Be Prepared for any Emergency</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${hero3})` }}
              >
                <div className="container-fluid">
                  <div className="slide-content">
                    <div className="slide-title-sub">
                      <h5>Safety in Knowledge.</h5>
                    </div>
                    <div className="slide-title">
                      <h2>SAFETY FROM THE FIRST STOP TO THE VERY LAST.</h2>
                    </div>
                    <div className="slide-text">
                      <p>Smart Safe reliable</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Hero2;
