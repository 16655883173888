import React, { Fragment } from "react";
// import PageTitle from "../pagetitle/PageTitle";
import Pricing from "../Pricing/Pricing";

const PricingPage = () => {
  return (
    <Fragment>
      {/* <PageTitle pageTitle={"Pricing"} pagesub={"Pricing"} /> */}
      <Pricing />
    </Fragment>
  );
};
export default PricingPage;
