import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import AI from "../../axiosInstance";

const Pricing = (props) => {
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState(1);
  const fetchPlans = useCallback(async () => {
    try {
      const res = await AI.get("/admin/getAllAdminPlan");
      const x = res.data.AdminPlan;
      setPlans([
        x?.find((i) => i?.planTypeId?.code === "BAC"),
        x?.find((i) => i?.planTypeId?.code === "ADV"),
        x?.find((i) => i?.planTypeId?.code === "PRM"),
      ]);
    } catch (error) {
      setPlans([]);
    }
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);
  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const fullYear = dateObject.getFullYear();
    return `${day}-${month}-${fullYear}`;
  };
  return (
    <section className="wpo-pricing-section section-padding" id="pricing">
      <div className="container">
        <SectionTitle subTitle={"Pricing"} Title={"Choose Your Pricing Plan"} />
        <div className="pricing-grids clearfix">
          {plans.length !== 0 &&
            plans.map((x, id) => {
              return (
                <div
                  className={`grid ${activePlan === id && "active"}`}
                  key={x?._id}
                  id={x?.planTypeId?.code}
                  onMouseEnter={() => setActivePlan(id)}
                >
                  <div className="type">
                    <h5>{x?.planTypeId?.planType}</h5>
                  </div>
                  {/* <div className="pricing-header">
                    <div>
                      <h3 className="price">{x.price}/-</h3>
                      <p>Per Month</p>
                    </div>
                  </div> */}
                  <div className="pricing-body" style={{ marginTop: 20 }}>
                    <ul>
                      <li>Real time Live Tracking</li>
                      {x?.planTypeId?.code !== "BAC" && (
                        <li>Real time Attendance</li>
                      )}
                      {x?.planTypeId?.code !== "BAC" && <li>Routing</li>}
                      <li>Driver App</li>
                      <li>Member App</li>
                      <li>Notification</li>
                      <li>SOS Alert</li>
                      <li>Payment</li>
                      {x?.planTypeId?.code === "PRM" && <li>Multi Shift</li>}
                    </ul>
                    {/* <div>
                    {(x?.planTypeId?.code === "BAC" ||
                      x?.planTypeId?.code === "ADV" ||
                      x?.planTypeId?.code === "PRM") && (
                      <FormControlLabel
                        className="checkBox"
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={() => {
                              setPaymentCheck(!paymentCheck);
                            }}
                            color="primary"
                          />
                        }
                        label="Payment"
                      />
                    )}
                    {(x?.planTypeId?.code === "ADV" ||
                      x?.planTypeId?.code === "PRM") && (
                      <FormControlLabel
                        className="checkBox"
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={() => {
                              setRfidCardCheck(!rfidCardCheck);
                            }}
                            color="primary"
                          />
                        }
                        label="RFID Card"
                      />
                    )}
                    {x?.planTypeId?.code === "PRM" && (
                      <FormControlLabel
                        className="checkBox"
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={() => {
                              setIsMultiShift(!isMultiShift);
                            }}
                            color="primary"
                          />
                        }
                        label="Multi-Shift"
                      />
                    )}
                  </div> */}
                    {x?.planTypeId?.code === "BAC" && (
                      <>
                        <br />
                        <br />
                        <br />
                        <br className="hidden-br" />
                        <br className="hidden-br" />
                      </>
                    )}
                    {x?.planTypeId?.code === "ADV" && (
                      <>
                        <br className="hidden-br" />
                        <br className="hidden-br" />
                      </>
                    )}
                    <ul>
                      <li style={{ fontSize: "20px" }}>
                        {x?.discount !== "0" ? (
                          <>
                            {/* <b>Discount: </b>
                            {x?.discount} <br />
                            Offer:- From {formatDate(x?.startDate)} <br /> to{" "}
                            {formatDate(x?.endDate)} */}
                            {/* static */}
                            <b
                              style={{
                                color: "rgb(255 0 24)",
                                fontWeight: "bolder",
                              }}
                            >
                              Free
                            </b>
                            <br />
                            From 1-04-2024 To 31-07-2024
                            {/* static */}
                          </>
                        ) : (
                          <>
                            <br />
                            <br />
                            <br />
                          </>
                        )}
                      </li>
                    </ul>
                    <Link
                      to={`/signup`}
                      state={{
                        planId: x?._id,
                        amount: x?.price,
                        discount: x?.discount,
                        plans,
                        paymentCheck: true,
                        isMultiShift: true,
                        rfidCardCheck: true,
                      }}
                      className="get-started"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
