import { googlemapapikey } from "../../envVars";
const getCoordinates = async (address) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${googlemapapikey}`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat: lat.toString(), lng: lng.toString() };
    }
  } catch (error) {
    console.error("Error geocoding location:", error);
  }
  return { lat: null, lng: null };
};
export { getCoordinates };
