import simg from "../images/service/1.jpg";
import simg2 from "../images/service/2.jpg";
import simg4 from "../images/service/4.jpg";
import sImgS1 from "../images/service-single/img-1.jpg";
import sImgS2 from "../images/service-single/img-2.jpg";
import sImgS4 from "../images/service-single/img-4.jpg";
import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";

import safeTransportation from "../images/custom/safe-transportation.png";
import customizedSol from "../images/custom/customized-solution.png";
import realTimeTrack from "../images/custom/real-time-tracking.png";
import birdEye from "../images/custom/bird-eye-view.png";
import noWait from "../images/custom/no-more-wait.png";
import sosAlarm from "../images/custom/sos-alarm.png";

const Services2 = [
  {
    Id: "1",
    sImg: simg,
    sImgS: sImgS1,
    sTitle: "Safe & Efficient Transportation",
    description:
      "Enhance member safety, streamline communication, and manage routes at the touch of a button",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: safeTransportation,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "2",
    sImg: simg2,
    sImgS: sImgS2,
    sTitle: "Customized Solutions",
    description:
      "Create and manage special cases such as one-way transport with special routes, stops & time slots - auto-map routes to maximize fuel and time efficiency.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: customizedSol,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "3",
    sImg: simg4,
    sImgS: sImgS4,
    sTitle: "Real time Tracking",
    description:
      "Management and parents can ensure the safety of their students outside the premises of school/company with the constant feedback from the real-time tracking app. ",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: realTimeTrack,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "4",
    sImg: simg4,
    sImgS: sImgS4,
    sTitle: "Bird's-Eye View",
    description:
      "Management can make important decision related to driver performance, safety of students, drivers behavior, live tracking and much more on the basis of live data.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: birdEye,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "5",
    sImg: simg4,
    sImgS: sImgS4,
    sTitle: "No More Wait",
    description:
      "With the route management system, parents can always know when school bus is going to drop off their kids at the pre marked stoppage as they will get notifications",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: noWait,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "6",
    sImg: simg4,
    sImgS: sImgS4,
    sTitle: "SOS alarm",
    description:
      "An SOS alarm alerts the admin or family members in case the vehicle breaks down during the trip. The admin immediately contacts the driver while viewing the location of the vehicle.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: sosAlarm,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
];

export default Services2;
