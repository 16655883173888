import React, { useEffect, useState } from "react";
import "./style.scss";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AI from "../../axiosInstance";
import { toast } from "react-toastify";
import Logo from "../../images/logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchakeyid } from "../../envVars";
import { getCoordinates } from "./addressFind";

const SignUpPage = (props) => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [planType, setPlanType] = useState("");
  const [border, setBorder] = useState(false);
  const [response, setResponse] = useState();
  const [business, setBusiness] = useState("");
  const [fetchData, setFetchData] = useState();

  useEffect(() => {
    if (state === null) {
      toast.info("Please select plan first!", { toastId: "yeyeyeye" });
      setTimeout(() => {
        window.location.href = "/#pricing";
      }, 3000);
    } else {
      const matchedPlan = state.plans.find((plan) => plan._id === state.planId);
      setPlanType(matchedPlan.planTypeId.planType);
    }
  }, [planType]);

  const commonSchema = Yup.object().shape({
    fname: Yup.string()
      .max(30, "First Name is too long!")
      .required("First Name is required"),
    lname: Yup.string()
      .max(30, "Last Name is too long!")
      .required("Last Name is required"),
    contactNo: Yup.string()
      .min(10, "Contact Number is not valid")
      .max(10, "Contact Number is not valid")
      .required("Contact Number is Required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .max(70, "Email is too long")
      .required("Email is required"),
    businessType: Yup.string().required("Business Type is required"),
  });
  const basicPlanSchema = commonSchema;
  const nonBasicPlanSchema = commonSchema.shape({
    companyName: Yup.string().required("Business Name is required"),
    address: Yup.string()
      .required("Address is required")
      .max(100, "Address is too long"),
  });

  const fetchBusinessTypes = async () => {
    try {
      const res = await AI.get("/admin/getAllBusinessType");
      setFetchData(res.data.BusinessTypes);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      address: "",
      contactNo: "",
      companyName: "",
      businessType: "",
      referencecode: "",
    },
    validateOnMount: true,
    validationSchema:
      planType === "Basic" ? basicPlanSchema : nonBasicPlanSchema,
    onSubmit: async ({
      fname,
      lname,
      contactNo,
      email,
      referencecode,
      address,
      companyName,
    }) => {
      if (isCaptchaVerified) {
        const toastId = toast.info("Loading...", { autoClose: false });
        if (address !== "") {
          var latlng = await getCoordinates(address);
        }
        const formData = {
          name: fname + " " + lname,
          email,
          contactNo: contactNo.toString(),
          referencecode,
          businessType: business,
          companyName,
          address,
          latitude: latlng?.lat || "",
          longitude: latlng?.lng || "",
          postCode: "",
          description: "",
          note: "",
          details: "",
          roleCode: "ORG",
        };
        await AI.post(
          `/master/organizationSignup?planId=${
            state?.planId
          }&paymentCheck=${true}&rfidCardCheck=${true}&isMultiShift=${true}`,
          formData
        )
          .then((res) => {
            setResponse(res.status);
            if (res.status === 200) {
              toast.update(toastId, {
                render: "Email already exists in our app",
                autoClose: 3000,
                type: "warning",
              });
            }
            if (res.status === 202) {
              toast.update(toastId, {
                render: "Contact number already exists in our app",
                autoClose: 3000,
                type: "warning",
              });
            }
            if (res.status === 204) {
              toast.update(toastId, {
                render: "Please Enter Valid Referral Code",
                autoClose: 3000,
                type: "warning",
              });
            }
            if (res.status === 201) {
              toast.update(toastId, {
                render:
                  "Sign up successful, Please check mail for verification link!",
                autoClose: 4000,
                type: "success",
              });
              setTimeout(() => {
                nav("/");
              }, 4000);
            }
          })
          .catch((err) => {
            toast.update(toastId, {
              render: "Something went wrong! Please try again after some time",
              autoClose: 3000,
              type: "error",
            });
          });
      } else {
        toast.warn("Please Fill up CAPTCHA", { autoClose: true });
      }
    },
  });
  const handleCaptchaChange = (value) => {
    if (value) {
      setIsCaptchaVerified(true);
    }
  };
  const { values, handleBlur, handleChange, handleSubmit, errors } = formik;

  return (
    <>
      <section className="login" style={{ height: "100vh" }}>
        <div className="loginBox">
          <Link to="/">
            <img src={Logo} alt="" height={80} width={200} />
          </Link>
          <div className="login-text">Sign Up</div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} className="input-container">
                <TextField
                  fullWidth
                  placeholder="Enter First Name"
                  value={values.fname}
                  variant="outlined"
                  name="fname"
                  error={formik.errors.fname && border}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChange(e)}
                />
                <span className="top-placeholder">First Name*</span>
              </Grid>
              <Grid item xs={6} className="input-container">
                <TextField
                  fullWidth
                  placeholder="Enter Last Name"
                  value={values.lname}
                  variant="outlined"
                  name="lname"
                  error={formik.errors.lname && border}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChange(e)}
                />
                <span className="top-placeholder">Last Name*</span>
              </Grid>
              <Grid item xs={6} className="input-container">
                <TextField
                  fullWidth
                  placeholder="Enter Contact Number"
                  value={values.contactNo}
                  variant="outlined"
                  name="contactNo"
                  error={formik.errors.contactNo && border}
                  onBlur={(e) => handleBlur(e)}
                  type="number"
                  onChange={(e) => handleChange(e)}
                />
                <span className="top-placeholder">Contact Number*</span>
              </Grid>
              <Grid item xs={6} className="input-container">
                <TextField
                  fullWidth
                  placeholder="Enter E-mail"
                  value={values.email}
                  variant="outlined"
                  name="email"
                  error={formik.errors.email && border}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChange(e)}
                />
                <span className="top-placeholder">Email*</span>
              </Grid>
              <Grid item xs={6} className="input-container">
                <label htmlFor="businessType">
                  <span className="top-placeholder" style={{ top: 2 }}>
                    Select Business Type*
                  </span>
                  <select
                    className={`form-control ${
                      business === "" && border ? "empty-form" : ""
                    }`}
                    id="businessType"
                    name="businessType"
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => {
                      handleChange(e);
                      setBusiness(e.target.value);
                    }}
                  >
                    <option value={""}>Select Business type</option>
                    {fetchData?.map((item) => {
                      return (
                        <option key={item?._id} value={item?._id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </Grid>
              <Grid item xs={6} className="input-container">
                <TextField
                  className={`form-control ${
                    formik.errors.companyName && border ? "empty-form" : ""
                  }`}
                  fullWidth
                  placeholder="Enter Business Name"
                  value={values.companyName}
                  variant="outlined"
                  name="companyName"
                  error={formik.errors.companyName && border}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChange(e)}
                />
                <span className="top-placeholder">
                  Business Name{planType === "Basic" ? "" : "*"}
                </span>
              </Grid>
              <Grid item xs={12} className="input-container">
                <TextField
                  fullWidth
                  placeholder="Enter Address"
                  value={values.address}
                  variant="outlined"
                  name="address"
                  error={formik.errors.address && border}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChange(e)}
                />
                <span className="top-placeholder">
                  Address{planType === "Basic" ? "" : "*"}
                </span>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReCAPTCHA
                  sitekey={recaptchakeyid}
                  onChange={handleCaptchaChange}
                />
              </Grid>
              <Grid
                item
                xs={6}
                className="input-container"
                style={{ marginTop: "15px" }}
              >
                <TextField
                  fullWidth
                  placeholder="Enter Referral Code"
                  value={values.referencecode}
                  variant="outlined"
                  name="referencecode"
                  error={response === 204 && border && values.referencecode}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChange(e)}
                />
                <span className="top-placeholder">Referral Code</span>
              </Grid>
              <Grid className="input-container" item xs={12}>
                <button
                  className="btn-signup"
                  type="submit"
                  onClick={() => {
                    setBorder(true);
                    if (Object.keys(errors).length === 0) {
                      return null;
                    } else {
                      toast.warn(errors[Object.keys(errors)[0]], {
                        position: "top-right",
                      });
                    }
                  }}
                >
                  Sign Up
                </button>
              </Grid>
            </Grid>
          </form>
        </div>
      </section>
    </>
  );
};

export default SignUpPage;
