import React from "react";
import simg from "../../images/mission-shape.png";
import mission from "../../images/custom/mission.png";
import vision from "../../images/custom/vision.png";
import service from "../../images/custom/service.png";
const MissionVission = (props) => {
  return (
    <section className="wpo-mission-vission-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-4  col-12">
            <div className="wpo-mission-item">
              <div className="wpo-mission-text">
                <div>
                  <div className="icon">
                    <img src={mission} alt="Mission" srcset="" />
                  </div>

                  <h2>Our Mission</h2>
                </div>
                <p>
                  We are committed to providing high-quality services to our
                  clients, benefitting society at large. Our mission is to
                  bridge the gap between the information technology challenges
                  and business goals with our innovative solutions.
                </p>
              </div>
              <div className="shape-2">
                <img src={simg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-4  col-12">
            <div className="wpo-vission-item">
              <div className="wpo-vission-text">
                <div>
                  <div className="icon">
                    <img src={vision} alt="Vision" srcset="" />
                  </div>
                  <h2>Our Vision</h2>
                </div>

                <p>
                  Our vision is to consistently deliver indigenous,
                  comprehensive, efficient, cost-effective, and top-notch
                  business solutions through IT solutions for the global market.
                </p>
              </div>
              <div className="shape-2">
                <img src={simg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-4  col-12">
            <div className="wpo-vission-item">
              <div className="wpo-vission-text">
                <div>
                  <div className="icon">
                    <img src={service} alt="Service" srcset="" />
                  </div>
                  <h2>Our Services</h2>
                </div>
                <p>
                  We roadmap your success from innovation to execution. From
                  defining innovating strategies, building digital capabilities
                  at an accelerated pace
                </p>
              </div>
              <div className="shape-2">
                <img src={simg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVission;
