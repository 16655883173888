import React from "react";
import ContactForm from "./ContactForm";

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-place"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Address</h2>
                      <p>C-205, Ganesh Glory 11, Gota, Ahmedabad, India</p>
                    </div>
                    <div className="shape-1" />
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-email"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Email Us</h2>
                      <p>sales@dropikme.com</p>
                      <p>info@dropikme.com</p>
                    </div>
                    <div className="shape-1" />
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-phone-call"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Call Now</h2>
                      <p>+91 xxx xxx xxx</p>
                      <p>+91 xxx xxx xxx</p>
                    </div>
                    <div className="shape-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-us-bg">
              <div className="wpo-contact-title">
                <h2>Get in touch with us</h2>
                <p>
                  Would You Like To Consult For Sales, Career, Or Other? Contact
                  Us
                </p>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        title="Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.5331128526145!2d72.53768187414994!3d23.114181112905587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2a382c2e0e2f%3A0xb54f4dd90bb6367e!2sNetpair%20Infotech!5e0!3m2!1sen!2sin!4v1687843704345!5m2!1sen!2sin"
        height={450}
        style={{ border: 0, width: "100%" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </section>
  );
};

export default Contactpage;
