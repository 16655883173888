import simg from "../images/service/1.jpg";
import simg2 from "../images/service/2.jpg";
import simg4 from "../images/service/4.jpg";

import sImgS1 from "../images/service-single/img-1.jpg";
import sImgS2 from "../images/service-single/img-2.jpg";
import sImgS4 from "../images/service-single/img-4.jpg";

import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";
import member from "../images/custom/member-05.jpg";
import driver from "../images/custom/driver.jpg";

//HOMEPAGE SERVICE

const Services = [
  {
    Id: 1,
    sImg: member,
    sImgS: sImgS4,
    sTitle: "Web Portal",
    description:
      "DropikMe offers your Transportation the tools, software and expertise to manage your own fleet of vehicles with zero-touch implementation and contextually assisted workflow.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-tax",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: 2,
    sImg: member,
    sImgS: sImgS1,
    sTitle: "Member App",
    description:
      "With Member App, Parents can get real-time location information about their children, their boarding and drop-off time.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: member,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: 3,
    sImg: driver,
    sImgS: sImgS2,
    sTitle: "Driver App",
    description:
      "Driver app helps the drivers with navigation, pickup points, marking attendance and raising SOS alarms",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: driver,
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
];

export default Services;
