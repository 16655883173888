import React from "react";
import { Link } from "react-router-dom";

const CtaAbout = ({ home }) => {
  return (
    <section className={`call-to-action-about ${home && "home"}`}>
      <div className="container">
        <div className="cta-wrapper">
          <div className="cta-text">
            <div>We’re here to help to grow your business.</div>
            <h3>Looking for the Best IT Business Solutions?</h3>
          </div>
          <div>
            <Link to={""} className="theme-btn">
              <span>Discover more</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaAbout;
