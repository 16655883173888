import React, { Fragment } from "react";
import PageTitle from "../pagetitle/PageTitle";
import MissionVission from "../MissionVission/MissionVission";
import About4 from "../about4/about4";
import TeamSection from "../TeamSection/TeamSection";
import Testimonial from "../Testimonial/Testimonial";
import PartnerSectionS2 from "../PartnerSection2/PartnerSectionS2";
import CtaAbout from "../Cta-about";
import banner from "../../images/about-us-banner.jpg";

const AboutPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={"About Us"} pagesub={"About"} banner={banner} />
      <MissionVission />
      <About4 />
      {/* <Skill2 /> */}
      <CtaAbout />
      <TeamSection tClass={"wpo-team-section-s3"} />
      <Testimonial />
      {/* <FunFact fClass={"wpo-fun-fact-section-s2 pt-0"} /> */}
      <PartnerSectionS2 />
    </Fragment>
  );
};
export default AboutPage;
