import React from "react";

const FeaturesContent = (props) => {
  const data = props.props;
  return (
    <div className="container">
      <div
        className={`p-5 row application_list ${
          data.flex_reverse ? "flex-row-reverse" : ""
        }`}
      >
        <div className="col-md-6 d-flex justify-content-center">
          <div>
            <h5>{data.heading}</h5>
            <div className="mt-3">{data.para}</div>
            <hr />
            <div className="d-flex gap-2 align-items-md-center">
              <img
                src={data.img1Src}
                alt=""
                height="50px"
                width="50px"
                className="shadow bg-white rounded"
              />
              <span className="mt-2">{data.image1Content}</span>
            </div>
            <div className="d-flex gap-2 mt-4 align-items-md-center">
              <img
                src={data.img2Src}
                alt=""
                height="50px"
                width="50px"
                className="shadow  bg-white rounded"
              />
              <span>{data.image2Content}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <div className={`ml-5 ${data.imageShadow ? "shadow" : ""}`}>
            <img src={data.mainImage} alt="dashboard" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesContent;
