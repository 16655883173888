const FAQs = [
  {
    title: "Why should use transport management software?",
    answer:
      "A transport management system helps schools to provide a safe commute for students. This software can route map, monitor drivers' behavior, and reduce fuel consumption levels to offer ease of mind to school administrators and parents.",
  },
  {
    title: "How do you manage transportation?",
    answer:
      "Manage routes Bus station allocations Accurate bus tracking Fleet tracking Driver's behavior monitoring and more",
  },
  {
    title: "Is it easy to use DropikMe transport management software?",
    answer:
      "We have developed a simple and graphic-rich interface that can be accessed by anyone. However, if you need any help, you can contact our team anytime.",
  },
];
export default FAQs;
