import React from "react";
import abimg from "../../images/about/ab.jpg";
import welcome from "../../images/welcome-dropik.png";

const About3 = (props) => {
  return (
    <section className="wpo-about-section-s3 section-padding">
      <div className="container">
        <div className="wpo-about-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-wrap">
                <div className="wpo-about-img">
                  <div className="inner-img">
                    <img src={abimg} alt="Welcome to DropikMe" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <div>
                  <h4>Welcome To DropikMe</h4>
                </div>
                <h2>Ride with us, You’ll make the best memories.</h2>
                <p>
                  We have built the most intuitive, innovative, and efficient
                  transportation management solution for all customer across the
                  country. Organization can track their entire fleet on the live
                  map along with traffic, speed and real-time status of active
                  vehicles.
                </p>
                <div className="text-sub-wrap">
                  <div className="text-sub-item">
                    <div className="icon">
                      <img src={welcome} alt="Welcome" srcset="" />
                    </div>
                    <h5>Leave the driving to us.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-ab"></div>
        </div>
      </div>
    </section>
  );
};

export default About3;
