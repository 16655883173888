import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AI from "../../axiosInstance";
import { REACT_APP_MAIN_APP_URL } from "../../envVars";
import "../SignUpPage/style.scss";
import Logo from "../../images/logo.svg";
import { Grid } from "@material-ui/core";
import { useCallback } from "react";

const Confirmation = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const linkRef = useRef(null);

  const verifyOrganizer = useCallback(async () => {
    setLoading(true);
    setError(true);
    try {
      await AI.put(
        "/master/verifyOrganizer",
        { isVerified: true },
        {
          headers: {
            Authorization: `Bearer ${id}`,
          },
        }
      );
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    verifyOrganizer();
  }, [verifyOrganizer]);

  return (
    <>
      {/* <PageTitle pageTitle={"Verification"} pagesub={"confirmation"} /> */}
      <section className="login" style={{ height: "100vh" }}>
        <div className="loginBox">
          <Link to="/">
            <img src={Logo} alt="" height={80} width={200} />
          </Link>
          {error && !loading && (
            <div className="error-div">
              Something went wrong, Try again after some time!
            </div>
          )}
          {error && loading && (
            <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
              Wait a moment while we verify your registration...
            </div>
          )}
          {!error && (
            <div>
              <div className="login-text success">Verification Successful!</div>
              <div style={{ marginBottom: 20 }}>
                Login to the web portal using button below..
              </div>
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid>
                <button
                  className={error ? "btn-signup disabled" : "btn-signup"}
                  type="button"
                  disabled={error}
                  onClick={() => {
                    if (!error) {
                      linkRef?.current?.click();
                    }
                  }}
                >
                  Login
                </button>
                <Link
                  to={`${REACT_APP_MAIN_APP_URL}`}
                  style={{
                    color: "white",
                    display: "none",
                    userSelect: "none",
                  }}
                  ref={linkRef}
                  target="_blank"
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default Confirmation;
