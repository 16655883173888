import React from "react";
import { Link, useLocation } from "react-router-dom";
import { REACT_APP_MAIN_APP_URL } from "../../envVars";
import AnchorLink from "react-anchor-link-smooth-scroll";

const HeaderTopbar3 = () => {
  const pathname = useLocation().pathname;
  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col col-lg-9 col-sm-12 col-12">
            <div className="contact-intro">
              <ul>
                {/* <li>
                  <i className="fi flaticon-phone-call-1"></i>(00) 0431 275 490
                </li> */}
                <li>
                  <i className="fi flaticon-email-1"></i>Sales@dropikme.com
                </li>
                <li>
                  <i className="fi flaticon-location"></i> Ahmedabad, Gujarat.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-12">
            <div className="contact-info">
              {pathname === "/" ? (
                <AnchorLink role="button" href={"#pricing"} className="signup">
                  Sign up
                </AnchorLink>
              ) : (
                <a role="button" href="/#pricing" className="signup">
                  Sign up
                </a>
              )}
              <Link
                className="theme-btn"
                to={`${REACT_APP_MAIN_APP_URL}`}
                target="_blank"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar3;
