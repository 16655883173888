//Dev
// export const REACT_APP_MAIN_APP_URL = "http://3.26.160.199:3000";
// export const API_BASE_URL = " http://3.26.160.199:8080/api";
// export const PUBLIC_URL = " http://3.26.160.199:3001/";

//Production
export const REACT_APP_MAIN_APP_URL = "https://my.dropikme.com";
export const API_BASE_URL = "https://api.dropikme.com/api";
export const PUBLIC_URL = " https://dropikme.com/";
export const recaptchakeyid = "6LfXBEEoAAAAAKLJ1fy_y7Bid5utlzowpnPsNWfK";

//common
export const googlemapapikey = "AIzaSyDI2VYPlaEaidbFGdNoMc8h9SgEZX2vFc8";
