import React from "react";

const BusImageContent = () => {
  const data = [
    {
      id: 1,
      src: "routemapping.svg",
      title: "Route mapping to improve students safety",
    },
    {
      id: 2,
      src: "fuelconsumption.svg",
      title: "Reduce fuel consumption",
    },
    {
      id: 3,
      src: "parentandteacher.svg",
      title: "Peace of mind for parents and teachers",
    },
    {
      id: 4,
      src: "driverefficiencybullet.svg",
      title: "Increase driver's efficiency",
    },
  ];
  return (
    <div className="container">
      <div className="row p-5">
        <div className="col-md-7 d-flex justify-content-center">
          <div>
            <h5 className="text-danger">Product Information</h5>
            <span className="h4 text-dark">
              What Is School Transport <br /> Management software?
            </span>
            <p className="mt-2">
              Helps to monitors location of school children during transit and
              the extensive research & exposure along with the intensive
              features which allows parents and school admin to keep an eye on
              their children with a simple user interface. Parents can ensure
              the safety of their children by installing comprehensive GPS
              tracking system on their Android or iOS devices.
            </p>
            <div className="mt-3 text-dark opacity-75">
              Here's how the TrackOlap school transport management system can
              help you :-
            </div>
            <div className="mt-5 gap-3">
              <div className="row content">
                {data.map((x) => (
                  <div key={x.id} className="col-md-6">
                    <div className="d-flex">
                      <img
                        src={x.src}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "20px",
                        }}
                        alt={x.src}
                      />
                      <p>{x.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 d-flex justify-content-center">
          <div>
            <img src="schooltransportsvg.svg" alt="school track" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusImageContent;
