import React from "react";
import FeaturesContent from "./FeaturesContent";

const index = () => {
  const data = [
    {
      flex_reverse: false,
      heading: "Real-Time Tracking",
      para: "School admin and parents can ensure the safety of their students outside the premises of school with the constant feedback from the real-time tracking app",
      img1Src: "livegpstracking.svg",
      image1Content: "Live Gps Vehicle Tracking",
      img2Src: "historyofmonth.svg",
      image2Content: "Tracking History of 3 months",
      mainImage: "dashboard.png",
      imageShadow: true,
    },
    {
      flex_reverse: true,
      heading: "Driver Behavior Monitoring",
      para: "To assure the safety of young children and reduce the operational cost, it is vital for schools to regularly monitor the behavior of drivers and maintain proper record.",
      img1Src: "drivereports.svg",
      image1Content: "Driver Reports",
      img2Src: "breakingalert.svg",
      image2Content: "Harsh Breaking Alert",
      mainImage: "dashboard.png",
      imageShadow: true,
    },
    {
      flex_reverse: false,
      heading: "Powerful Reports",
      para: "School management can make important decision related to vehicle performance, safety of students, drivers behaviour and much more on the basis of 90 days data and create relevant reports.",
      img1Src: "usagereports.svg",
      image1Content: "Vehicle Usage Reports",
      img2Src: "schedulereports.svg",
      image2Content: "Schedule Reports",
      mainImage: "powerfulreports.svg",
      imageShadow: false,
    },
    {
      flex_reverse: true,
      heading: "Parent Mobile App",
      para: "Parents can track down the movement of their children's school bus during the school hours in real time and can access the drivers information with one click of their finger",
      img1Src: "livetrackingbullet.svg",
      image1Content: "Live Tracking",
      img2Src: "schedulereports.svg",
      image2Content: "Driver details",
      mainImage: "parentmobileapp.svg",
      imageShadow: false,
    },
    {
      flex_reverse: false,
      heading: "Real-Time Notifications",
      para: "Timely notifications can alleviate the burden on both parents and school administrators by providing updates on various crucial events such as bus arrivals, student boarding, arrival at school, departure from school, and safe arrival back home",
      img1Src: "livetrackingbullet.svg",
      image1Content: "Ignition & Rash Driving Alerts",
      img2Src: "schedulereports.svg",
      image2Content: "Vehicle Over-speed Alert",
      mainImage: "parentmobileapp.svg",
      imageShadow: false,
    },
    {
      flex_reverse: true,
      heading: "Route Management",
      para: "Assists school administration in strategically planning the school bus route and determining optimal stop locations based on the convenience of parents",
      img1Src: "livetrackingbullet.svg",
      image1Content: "Route design",
      img2Src: "schedulereports.svg",
      image2Content: "Mark Stoppage",
      mainImage: "parentmobileapp.svg",
      imageShadow: false,
    },
    {
      flex_reverse: false,
      heading: "Cancel Pick Up/Drop",
      para: "Parents have the convenience of directly communicating with the school administration via a mobile application, enabling them to easily request the cancellation of pick-up or drop-off services for any particular day.",
      img1Src: "livetrackingbullet.svg",
      image1Content: "Mobile app based",
      img2Src: "schedulereports.svg",
      image2Content: "Notification to Admin and Driver",
      mainImage: "parentmobileapp.svg",
      imageShadow: false,
    },
  ];
  return (
    <div style={{ background: "#1e5bfa0d" }}>
      <div className="container p-5">
        <div className="text-center">
          <div>
            <h6 className="text-danger">Amazing Features!</h6>
          </div>
          <span className="h4 text-dark">
            Manage School Transport With Ease, With <br />
            Our System's Amazing Features
          </span>
        </div>
      </div>
      {data.map((x, key) => {
        return (
          <React.Fragment key={key}>
            <FeaturesContent props={x} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default index;
