import React, { Fragment } from "react";
import PageTitle from "../pagetitle/PageTitle";
import Contactpage from "./Contactpage";
import banner from "../../images/contact-banner.jpg";

const Contact = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={"Contact Us"} pagesub={"Contact"} banner={banner} />
      <Contactpage />

      <section className="call-to-action">
        <div className="row g-0">
          <div className="column col-lg-6 col-md-12 col-sm-12 call-to-action-left">
            <div>
              <h2>
                Remodel your Business <br /> with the power of ZOHO{" "}
              </h2>
            </div>
          </div>
          <div className="column col-lg-6 col-md-12 col-sm-12 call-to-action-right">
            <div>
              <h2>
                All solutions <br /> at one place
              </h2>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Contact;
