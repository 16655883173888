import React from "react";
import { Link } from "react-router-dom";

const ApplicationList = () => {
  const data = [
    {
      id: 1,
      title: "Parents Application",
    },
    {
      id: 2,
      title: "Driver Application",
    },
    {
      id: 3,
      title: "Attendant Application",
    },
    {
      id: 4,
      title: "Member Application",
    },
  ];
  return (
    <div style={{ background: "#1e5bfa0d" }}>
      <div className="container">
        <div className="p-5 application_list">
          <div className=" d-flex justify-content-center">
            <div>
              <h2>Keep your Kid’s Safe With School Transport Management</h2>
              <div className="mt-3 school_content">
                TrackOlap School Transport Management dashboard empower school
                admins and parents to keep children safe on the roads by
                constantly monitoring school vehicle’s moments in real-time.
              </div>
              <div className="mt-5 gap-3">
                <div className="row content">
                  {data.map((x) => (
                    <div key={x.id} className="col-md-6">
                      <div className="d-flex">
                        <img
                          src="checkIcon.png"
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "2%",
                          }}
                          alt=""
                        />
                        <p>{x.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Link
                className="theme-btn cursor-pointer px-5 py-3 mt-3"
                to="/?bookdemo=demo"
              >
                Request a Demo
              </Link>
            </div>
          </div>
          <div className=" d-flex justify-content-center image_div">
            <div className="ml-5 shadow mb-5 bg-white rounded img-fluid">
              <img src="dashboard.png" alt="dashboard" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationList;
