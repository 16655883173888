// import pimg1 from '../images/project/img-1.jpg'
// import pimg2 from '../images/project/img-2.jpg'
// import pimg3 from '../images/project/img-3.jpg'
// import pimg4 from '../images/project/img-31.jpg'
// import pimg5 from '../images/project/img-4.jpg'
// import pimg6 from '../images/project/img-5.jpg'
// import pimg7 from '../images/project/img-6.jpg'
// import pimg8 from '../images/project/img-7.jpg'
// import pimg9 from "../images/project/img-8.jpg";
// import pimg10 from "../images/project/1.png";
// import pimg11 from "../images/project/2.png";
// import pimg12 from "../images/project/3.png";
// import pimg13 from "../images/project/4.png";

import ps1img1 from "../images/project-single/1.jpg";
import ps1img2 from "../images/project-single/2.jpg";
import ps1img3 from "../images/project-single/3.jpg";
import ps1img4 from "../images/project-single/4.jpg";
import ps1img5 from "../images/project-single/1.jpg";
import ps1img6 from "../images/project-single/2.jpg";
import ps1img7 from "../images/project-single/3.jpg";
import ps1img8 from "../images/project-single/5.jpg";
// import ps1img9 from "../images/project-single/6.jpg";

import psub1img1 from "../images/project-single/p1.jpg";
import psub1img2 from "../images/project-single/p2.jpg";
// ----------------------netPairWebsiteImages----------------
import pimg1 from "../images/projectimg/01.png";
import pimg2 from "../images/projectimg/02.png";
import pimg3 from "../images/projectimg/03.png";
import pimg4 from "../images/projectimg/04.png";
import pimg5 from "../images/projectimg/05.png";
import pimg6 from "../images/projectimg/06.png";
import pimg7 from "../images/projectimg/07.png";
import pimg8 from "../images/projectimg/08.png";

const Projects = [
  {
    Id: "1",
    pImg: pimg1,
    ps1img: ps1img1,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Peepdo",
    // subTitle: "Business Consulting",
  },
  {
    Id: "2",
    pImg: pimg2,
    ps1img: ps1img2,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Utility Sentry",
    // subTitle: "Interior Design",
  },
  {
    Id: "3",
    pImg: pimg3,
    ps1img: ps1img3,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Fast VPN",
    // subTitle: "Business Consulting",
  },
  {
    Id: "4",
    pImg: pimg4,
    ps1img: ps1img4,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Hum Chat",
    // subTitle: "Business Consulting",
  },
  {
    Id: "5",
    pImg: pimg5,
    ps1img: ps1img5,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Geologon",
    // subTitle: "Business Consulting",
  },
  {
    Id: "6",
    pImg: pimg6,
    ps1img: ps1img6,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Tropic Buddha Yoga",
    // subTitle: "Business Consulting",
  },
  {
    Id: "7",
    pImg: pimg7,
    ps1img: ps1img7,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Call Recorder",
    // subTitle: "Business Consulting",
  },
  {
    Id: "8",
    pImg: pimg8,
    ps1img: ps1img8,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Villa De Skin",
    // subTitle: "Business Consulting",
  },
  //   {
  //     Id: "9",
  //     pImg: pimg9,
  //     ps1img: ps1img9,
  //     psub1img1: psub1img1,
  //     psub1img2: psub1img2,
  //     title: "Investment planning",
  //     subTitle: "Business Consulting",
  //   },
  //   {
  //     Id: "10",
  //     pImg: pimg10,
  //     ps1img: ps1img6,
  //     psub1img1: psub1img1,
  //     psub1img2: psub1img2,
  //     title: "Investment planning",
  //     subTitle: "Business Consulting",
  //   },
  //   {
  //     Id: "11",
  //     pImg: pimg11,
  //     ps1img: ps1img7,
  //     psub1img1: psub1img1,
  //     psub1img2: psub1img2,
  //     title: "Business Analysis",
  //     subTitle: "Business Consulting",
  //   },
  //   {
  //     Id: "12",
  //     pImg: pimg12,
  //     ps1img: ps1img8,
  //     psub1img1: psub1img1,
  //     psub1img2: psub1img2,
  //     title: "Financial Advices",
  //     subTitle: "Business Consulting",
  //   },
  //   {
  //     Id: "13",
  //     pImg: pimg13,
  //     ps1img: ps1img9,
  //     psub1img1: psub1img1,
  //     psub1img2: psub1img2,
  //     title: "Investment planning",
  //     subTitle: "Business Consulting",
  //   },
];

export default Projects;
