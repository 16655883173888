import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from "../../images/testimonial/New Project.png";
import ts2 from "../../images/testimonial/New Project.png";
import ts3 from "../../images/testimonial/New Project.png";
// import VideoModal from "../ModalVideo/VideoModal";
import SectionTitle from "../SectionTitle/SectionTitle";

const testimonial = [
  {
    id: "01",
    tImg: ts1,
    Des: "Netpair Infotech has the best resources to take on any job. They are professional, deliver the projects on time and always meet their commitments.  We have engaged in a long term business relationship and we are very pleased to do so.",
    Title: "Rajnikanth",
    Sub: "CEO",
  },
  {
    id: "01",
    tImg: ts2,
    Des: "Not only has Netpair infotech taken an existing software product and continued to support IT services, they have made all aspects of the platform better!  They have managed to add so much more value to the Utility Sentry platform for all users.  In fact, I have two new startup companies, HeritageCreators and NativRebel, which I already have Netpair infotech working on.",
    Title: "Guy R. Monagas",
    Sub: "President/CEO",
  },
  {
    id: "01",
    tImg: ts3,
    Des: "Netpair Infotech has been our trusted partners for majority of our development needs. We have had meetings both at our London office and Netpair Infotech's India office and these helped us know each other better. I am sure we are in for a long term engagement  with Netpair Infotech. Strongly recommended them.",
    Title: "Service Manager",
    Sub: "Milap Patel",
  },
];

const Testimonial = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  return (
    <section className="wpo-testimonial-section section-padding">
      <div className="container">
        <div className="wpo-testimonial-wrap">
          <div className="row">
            <div className="col-lg-6">
              <SectionTitle
                subTitle={"CLIENT TESTIMONIAL"}
                Title={"Best Word Our Clients"}
              />
              <div className="wpo-testimonial-items">
                <Slider
                  asNavFor={nav2}
                  ref={(slider1) => setNav1(slider1)}
                  dots={true}
                  arrows={false}
                >
                  {testimonial.map((tesmnl, tsm) => (
                    <div className="wpo-testimonial-item" key={tsm}>
                      <div className="wpo-testimonial-text">
                        <p>“{tesmnl.Des}”</p>
                        <div className="wpo-testimonial-text-btm">
                          <span>{tesmnl.Sub}</span>
                          <h3>{tesmnl.Title}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="testimonial-right-side">
                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={1}
                  fade={true}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  {testimonial.map((tesmnl, tsm) => (
                    <div className="testimonial-img" key={tsm}>
                      <img src={tesmnl.tImg} alt="" />
                    </div>
                  ))}
                </Slider>
                <div className="video-holder">{/* <VideoModal /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
