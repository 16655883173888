import timg1 from "../images/team/team-1.jpg";
import timg2 from "../images/team/team-2-150x150.png";
import timg3 from "../images/team/3.jpg";

const Teams = [
  {
    Id: "1",
    tImg: timg1,
    name: "YUVRAJSINH PARMAR",
    title: "CEO & FOUNDER",
  },
  {
    Id: "2",
    tImg: timg2,
    name: "Hetal Parimal Thakkar",
    title: "CO-FOUNDER",
  },
  // {
  //   Id: "3",
  //   tImg: timg3,
  //   name: "Jenelia Orkid",
  //   title: "Merketer",
  // },
];

export default Teams;
