import React, { useRef, useEffect } from "react";
import Contact from "./contact";
import "./style.css";
import FAQs from "../../api/faqs";
import { useLocation } from "react-router-dom";

const TalkToExpert = () => {
  const bookDemoRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const bookDemoParam = params.get("bookdemo");
    if (bookDemoRef.current && bookDemoParam === "demo") {
      bookDemoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.search]);

  return (
    <>
      <div className="book-your-demo" id="bookdemo" ref={bookDemoRef}>
        <div className="container" style={{ padding: "50px 0" }}>
          <div className="row">
            <div className="wpo-service-single-area col col-12 col-lg-6">
              <div className="container">
                <div className="row">
                  <div className="wpo-service-single-wrap">
                    <div className="wpo-service-single-item">
                      <div className="wpo-service-contact-area">
                        <div className="wpo-contact-title">
                          <h2>Book Your Demo</h2>
                          <p style={{ color: "#ea9c34", fontWeight: 900 }}>
                            Let's connect!
                          </p>
                        </div>
                        <div className="wpo-contact-form-area">
                          <Contact />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-sidebar col col-12 col-lg-6">
              <div className="widget category-widget">
                <div className="wpo-contact-title">
                  <h2>FAQs</h2>
                  <p style={{ color: "#ea9c34", fontWeight: 900 }}>
                    QUESTIONS & ANSWERS
                  </p>
                </div>
                <ul className="accordion-wrap">
                  {FAQs.map(({ title, answer }, index) => (
                    <details key={title} className="accordion-item" open={true}>
                      <summary>
                        <h6>{title}</h6>
                      </summary>
                      <p>{answer}</p>
                    </details>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalkToExpert;
